import Media from 'react-media'
import  DocumentTitle from 'react-document-title'
import volvoIMG from './img/volvo.jpg'
import hammelIMG from './img/hammel.jpg'
import harvestIMG from './img/harvest.jpg'
import hitachiIMG from './img/hitachi.jpg'
import mulcherIMG from './img/mulcher.jpg'
import turboIMG from './img/turbo.jpg'
import 'react-responsive-carousel/lib/styles/carousel.css'
import { Carousel } from 'react-responsive-carousel'
import React, { useEffect } from 'react'
import Roll from 'react-reveal/Roll'
import Rotate  from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

export default function Home(props){
    return(
      <DocumentTitle title={'Компания СпецТехТранс [г.Москва]'}> 
      <section id="home">
      <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (
          <Fade top>
            <h1>Компания СпецТехТранс</h1>
          </Fade>
          ) : (
          <Rotate top left>
            <h1>Компания СпецТехТранс</h1>
          </Rotate>
        )}  
        </Media>
            <article>        
              <div className="flex" infiniteLoop="true" interval="2000">
              <Media queries = {{
                small: '(max-width:960px)',
              }}>
              {(size) => size.small ? (
                <Carousel autoPlay="true">
                <div><figure><figcaption>Щепоруб <span>[Volvo Brooks]</span></figcaption><img src={volvoIMG} alt="Щепоруб [Volvo Brooks]" /></figure></div>
                <div><figure><figcaption>Шредер <span>[Hammel 750]</span></figcaption><img src={hammelIMG} alt="Шредер [Hammel 750]" /></figure></div>
                <div><figure><figcaption>Харвестер <span>[TimberJack 1270]</span></figcaption><img src={harvestIMG} alt="Харвестер [TimberJack 1270]" /></figure></div>
                <div><figure><figcaption>Корчеватель <span>[Hitachi 240-3]</span></figcaption><img src={hitachiIMG} alt="Корчеватель экскаватор [Hitachi 240-3]" /></figure></div>
                <div><figure><figcaption>Мульчер <span>[GALOTRAX-300]</span></figcaption><img src={mulcherIMG} alt="Мульчер [GALOTRAX-300]" /></figure></div>
                <div><figure><figcaption>Сжигание порубочных остатков</figcaption><img src={turboIMG} alt="Сжигание порубочных остатков" /></figure></div>
                </Carousel>
              ) : (
                <Roll right cascade>
                <div><figure><figcaption>Щепоруб <span>[Volvo Brooks]</span></figcaption><img src={volvoIMG} alt="Щепоруб [Volvo Brooks]" /></figure></div>
                <div><figure><figcaption>Шредер <span>[Hammel 750]</span></figcaption><img src={hammelIMG} alt="Шредер [Hammel 750]" /></figure></div>
                <div><figure><figcaption>Харвестер <span>[TimberJack 1270]</span></figcaption><img src={harvestIMG} alt="Харвестер [TimberJack 1270]" /></figure></div>
                <div><figure><figcaption>Корчеватель <span>[Hitachi 240-3]</span></figcaption><img src={hitachiIMG} alt="Корчеватель экскаватор [Hitachi 240-3]" /></figure></div>
                <div><figure><figcaption>Мульчер <span>[GALOTRAX-300]</span></figcaption><img src={mulcherIMG} alt="Мульчер [GALOTRAX-300]" /></figure></div>
                <div><figure><figcaption>Сжигание порубочных остатков</figcaption><img src={turboIMG} alt="Сжигание порубочных остатков" /></figure></div>
                </Roll>   
              )}  
              </Media>
              </div>
              <h3>Компания ООО СпецТехТранс <span>[ г.Москва ]</span></h3>
              <p>Мы специализируемся на аренде спецтехники, вырубке леса, лесозаготовки комплексом, прокладке просек, переработке деревьев с корнями и КГО (крупно габаритные отходы). Также производим корчевание корней деревьев и сжигание порубочных остатков и отходов древесины.</p>
              <p>Также оказываем услуги по корчевыванию пней и вырубке деревьев. Уборке бурелома и ветровала, созданию просек и разработке лесосек. Оказываем услугу по комплексной расчистке участков ПОД КЛЮЧ</p>
              <p>Предлагаем в аренду технику на любой срок от одного дня до нескольких месяцев.</p>
              <p>Вся техника сдается в аренду в сопровождении опытных машинистов, готовых провести работы любой степени сложности.</p>
              <p>Работы производятся только по авансовой схеме расчетов. Производится предоплата за 50 часов.</p>
              <p>Отдельно оплачивается доставка техники на объект аренды и в случае короткого периода аренды (менее 100 часов) также оплачивается возврат на место стоянки.</p>
            </article>
      </section>
      </DocumentTitle>
    );
    
}