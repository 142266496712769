import { Routes, Route, NavLink, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Media from 'react-media'
import Home from './Home'
import Volvo from './Volvo'
import Mulcher from './Mulcher'
import Hammel from './Hammel'
import Harvest from './Harvest'
import Hitachi from './Hitachi'
import mulcher from './Mulcher'
import Turbo from './Turbo'
import Contact from './Contact'
import Props from './Props'
import './css/App.css'
import bgIMG from './img/bg.png'
import bgIMGs from './img/bg-s.png'
import Flip from 'react-reveal/Flip'
import Roll from 'react-reveal/Roll'
import HeadShake from 'react-reveal/HeadShake'

function App() {
  const location = useLocation()
  const scrollContent = document.getElementById('content')
  const clicked = useState(true)

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }, []);

  const [isActive, setActive] = useState(false)

  function handleTogglerClick(evt){
    setActive((prevState) => !prevState)
  }

  function scrollToTop(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
  }
  
  return (
      <section id="wrap">
        <header id="logo">
        <h1>СпецТехТранс<span>Москва и область</span></h1>
        <a href="tel:+7(906)730-01-21"><Roll left cascade delay="4000"><h2>+7(906)730-01-21</h2></Roll></a>
            <div id="nav-icon2" className={isActive ? 'open' : ''} onClick={handleTogglerClick}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <nav id="menu" className={isActive ? 'active' : 'hidden'} onClick={handleTogglerClick}>
            <Flip top cascade>
              <NavLink to="/" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')} onClick={ scrollToTop }>Главная</NavLink>
              <NavLink to="/volvo" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')} onClick={ scrollToTop }>Щепоруб</NavLink>
              <NavLink to="/mulcher" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')} onClick={ scrollToTop }>Мульчер</NavLink>
              <NavLink to="/harvest" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')} onClick={ scrollToTop }>Харвестер</NavLink>
              <NavLink to="/hammel" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')} onClick={ scrollToTop }>Шредер</NavLink>
              <NavLink to="/hitachi" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')} onClick={ scrollToTop }>Корчеватель</NavLink>
              <NavLink to="/turbo" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')} onClick={ scrollToTop }>Сжигание</NavLink>
              <NavLink to="/contact" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')} onClick={ scrollToTop }>Контакты</NavLink>
              <NavLink to="/props" className={({ isActive }) => 'navbar-item' + (isActive ? ' active' : '')} onClick={ scrollToTop }>Реквизиты</NavLink>
            </Flip>
            </nav>
        </header>
        <main id="content" className={isActive ? 'hidden' : 'active'}>
            <Routes location={location}>
              <Route path="/" element={<Home />} />
              <Route path="/volvo" element={<Volvo />} />
              <Route path="/mulcher" element={<Mulcher />} /> 
              <Route path="/harvest" element={<Harvest />} /> 
              <Route path="/hammel" element={<Hammel />} /> 
              <Route path="/hitachi" element={<Hitachi />} /> 
              <Route path="/turbo" element={<Turbo />} /> 
              <Route path="/contact" element={<Contact />} /> 
              <Route path="/props" element={<Props />} /> 
           </Routes>
        <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (<HeadShake delay="7000" count="2"><img src={bgIMGs} id="bgIMG" /><p id="copyright">[c] 2023 ООО "Спецтехтранс" [Москва]</p></HeadShake>) : (<HeadShake delay="7000" count="2"><img src={bgIMG} id="bgIMG" /><p id="copyright">[c] 2023 ООО "Спецтехтранс" [Москва]</p></HeadShake>)}  
        </Media>
        </main>
      </section>
  );
}

export default App;
