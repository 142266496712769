import Media from 'react-media'
import  DocumentTitle from 'react-document-title'
import harvestIMG from './img/harvest.jpg'
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

export default function Harvest(props){
    return(
      <DocumentTitle title={'Харвестер для лесозаготовки [TimberJack 1270]'}> 
        <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (
          <section id="unit">
          <Fade top>
            <h1>Харвестер для лесозаготовки</h1>
          </Fade>
          <Fade top>
            <article>
              <figure><figcaption>Харвестер [TimberJack 1270]</figcaption><img src={harvestIMG} alt="Харвестер [TimberJack 1270]" /></figure>
              <p>Предоставим Харвестер с оператором в аренду на территории Москвы и Московской области. Работаем от 3ГА, организуем доставку на объект.</p>
              <h3>Технические характеристики харвестера</h3>
              <ul>
                <li>Масса <span>16900 кг</span></li>
                <li>Длина <span>9950 мм</span></li>
                <li>Ширина <span>2960 мм</span></li>
                <li>Клиренс <span>700 мм</span></li>
                <li>Мощность <span>180 кВт</span></li>
                <li>Шины: <span>600/34</span></li>
                <li>Аренда цена <span>договорная руб/м3</span></li>
              </ul>
              <p>Харвестер 1270 популярен во всем мире, он обладает проверенной надежностью и высокой производительностью в наиболее сложных условиях рубок промежуточного и главного пользования.</p>
            </article>
          </Fade>
        </section>
          ) : (
        <section id="unit">
          <Rotate top left>
            <h1>Харвестер для лесозаготовки</h1>
          </Rotate>
          <Rotate bottom left>
            <article>
              <figure><figcaption>Харвестер [TimberJack 1270]</figcaption><img src={harvestIMG} alt="Харвестер [TimberJack 1270]" /></figure>
              <p>Предоставим Харвестер с оператором в аренду на территории Москвы и Московской области. Работаем от 3ГА, организуем доставку на объект.</p>
              <h3>Технические характеристики корчевателя</h3>
              <ul>
                <li>Масса <span>16900 кг</span></li>
                <li>Длина <span>9950 мм</span></li>
                <li>Ширина <span>2960 мм</span></li>
                <li>Клиренс <span>700 мм</span></li>
                <li>Мощность <span>180 кВт</span></li>
                <li>Шины: <span>600/34</span></li>
                <li>Аренда цена <span>договорная руб/м3</span></li>
              </ul>
              <p>Харвестер 1270 популярен во всем мире, он обладает проверенной надежностью и высокой производительностью в наиболее сложных условиях рубок промежуточного и главного пользования.</p>
            </article>
          </Rotate>
        </section>
        )}  
        </Media>
      </DocumentTitle>
    );
    
}