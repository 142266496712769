import Media from 'react-media'
import  DocumentTitle from 'react-document-title'
import mulcherIMG from './img/mulcher.jpg'
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

export default function Mulcher(props){
    return(
      <DocumentTitle title={'Лесной мульчер для порубочных остатков [GALOTRAX-300]'}> 
        <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (
          <section id="unit">
          <Fade top>
            <h1>Лесной мульчер для порубочных</h1>
          </Fade>
          <Fade top>
            <article>
              <figure><figcaption>Мульчер [GALOTRAX-300]</figcaption><img src={mulcherIMG} alt="Корчеватель с гидроножницами" /></figure>
              <p>Утилизация порубочных остатков мульчером является одним из самых эффективных способов решения проблемы отходов лесной промышленности. Порубочные остатки представляют собой мелкую древесную щепу, ветки и другие побочные продукты лесозаготовки, которые могут привести к различным проблемам, если не утилизировать их правильным образом.</p>
              <h3>Технические характеристики мульчера</h3>
              <ul>
                <li>Мощность: <span>300 л.с.</span></li>
                <li>Производительность: <span>1..2 ГА в день</span></li>
                <li>Аренда от <span>от 180 000 руб/га</span></li>
              </ul>
              <p>Мульчер - это мощная машина, способная измельчить порубочные остатки до мелкого состояния, которые затем могут быть использованы в качестве органического удобрения или материала для биотехнической рекультивации. Процесс мульчирования помогает уменьшить объем отходов и предотвратить загрязнение окружающей среды.</p>
              <p>Преимущества утилизации порубочных остатков мульчером многочисленны. Мульчированные остатки могут быть использованы в сельском хозяйстве в качестве натурального удобрения, что способствует повышению плодородия почвы и росту растений. Это также способствует сохранению влаги в почве и снижению необходимости в использовании химических удобрений.</p>
              <p>Мульчированные остатки могут быть использованы для биотехнической рекультивации деградированных земель или промышленных площадок. Они помогают восстановить структуру почвы, улучшают ее дренаж и стимулируют развитие микроорганизмов, необходимых для здорового растительного роста.</p>
              <p>Утилизация порубочных остатков мульчером помогает уменьшить риск возникновения лесных пожаров. Измельченные остатки впитывают влагу, что является естественной защитой от пожаров, а также предотвращают накопление сухих материалов, которые могут служить источником пожара.</p>
              <p>Наконец, работа мульчера также способствует улучшению экономической эффективности лесозаготовки. Утилизация порубочных остатков мульчером позволяет сократить затраты на управление отходами и утилизацию, а также создает новые возможности для использования мульчи в различных отраслях.</p>
              <p>В заключение, утилизация порубочных остатков мульчером является важным шагом в направлении более устойчивого использования лесных ресурсов. Она помогает уменьшить отходы, улучшить почву, предотвратить пожары и создать новые возможности для использования. Эта практика имеет не только экологическую, но и экономическую пользу и является важным элементом современного управления лесными ресурсами.</p>
            </article>
          </Fade>
        </section>

          ) : (
        <section id="unit">
          <Rotate top left>
            <h1>Лесной мульчер для порубочных</h1>
          </Rotate>
          <Rotate bottom left>
            <article>
              <figure><figcaption>Мульчер [GALOTRAX-300]</figcaption><img src={mulcherIMG} alt="Корчеватель с гидроножницами" /></figure>
              <p>Утилизация порубочных остатков мульчером является одним из самых эффективных способов решения проблемы отходов лесной промышленности. Порубочные остатки представляют собой мелкую древесную щепу, ветки и другие побочные продукты лесозаготовки, которые могут привести к различным проблемам, если не утилизировать их правильным образом.</p>
              <h3>Технические характеристики мульчера</h3>
              <ul>
                <li>Мощность: <span>300 л.с.</span></li>
                <li>Производительность: <span>1..2 ГА в день</span></li>
                <li>Аренда от <span>от 180 000 руб/га</span></li>
              </ul>
              <p>Мульчер - это мощная машина, способная измельчить порубочные остатки до мелкого состояния, которые затем могут быть использованы в качестве органического удобрения или материала для биотехнической рекультивации. Процесс мульчирования помогает уменьшить объем отходов и предотвратить загрязнение окружающей среды.</p>
              <p>Преимущества утилизации порубочных остатков мульчером многочисленны. Мульчированные остатки могут быть использованы в сельском хозяйстве в качестве натурального удобрения, что способствует повышению плодородия почвы и росту растений. Это также способствует сохранению влаги в почве и снижению необходимости в использовании химических удобрений.</p>
              <p>Мульчированные остатки могут быть использованы для биотехнической рекультивации деградированных земель или промышленных площадок. Они помогают восстановить структуру почвы, улучшают ее дренаж и стимулируют развитие микроорганизмов, необходимых для здорового растительного роста.</p>
              <p>Утилизация порубочных остатков мульчером помогает уменьшить риск возникновения лесных пожаров. Измельченные остатки впитывают влагу, что является естественной защитой от пожаров, а также предотвращают накопление сухих материалов, которые могут служить источником пожара.</p>
              <p>Наконец, работа мульчера также способствует улучшению экономической эффективности лесозаготовки. Утилизация порубочных остатков мульчером позволяет сократить затраты на управление отходами и утилизацию, а также создает новые возможности для использования мульчи в различных отраслях.</p>
              <p>В заключение, утилизация порубочных остатков мульчером является важным шагом в направлении более устойчивого использования лесных ресурсов. Она помогает уменьшить отходы, улучшить почву, предотвратить пожары и создать новые возможности для использования. Эта практика имеет не только экологическую, но и экономическую пользу и является важным элементом современного управления лесными ресурсами.</p>
            </article>
          </Rotate>
        </section>

        )}  
        </Media>
      </DocumentTitle>
    );
    
}