import Media from 'react-media'
import  DocumentTitle from 'react-document-title'
import LightSpeed from 'react-reveal/LightSpeed'
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

export default function Props(props){
    return(
      <DocumentTitle title={'Реквизиты ООО СпецТехТранс [г.Москва]'}> 
        <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (
          <section id="unit">
            <Fade top>
              <h1>Реквизиты ООО СпецТехТранс</h1>
            </Fade>
            <article>
            <Fade top>
              <ul>
                <li>ИНН <span>9715309788</span></li>
                <li>КПП <span>771501001</span></li>
                <li>Свид.ОГРН <span>№ 5177746288704</span> От 5 декабря 2017г.</li>
                <li><span>АО «АЛЬФА-БАНК» г. Москва</span></li>
                <li>Счёт <span>40702 810 8 0186 0000101</span></li>
                <li>ИНН <span>9715309788</span> КПП <span>771501001</span></li>
                <li>БИК <span>044525593</span></li>
                <li>Кор. счёт <span>30101810200000000593</span></li>
              </ul>
            </Fade>  
            </article>
        </section>
          ) : (
        <section id="unit">
            <Rotate top left>
              <h1>Реквизиты ООО СпецТехТранс</h1>
            </Rotate>
            <article>
            <LightSpeed right cascade>
              <ul>
                <li>ИНН <span>9715309788</span></li>
                <li>КПП <span>771501001</span></li>
                <li>Свид.ОГРН <span>№ 5177746288704</span> От 5 декабря 2017г.</li>
                <li><span>АО «АЛЬФА-БАНК» г. Москва</span></li>
                <li>Счёт <span>40702 810 8 0186 0000101</span></li>
                <li>ИНН <span>9715309788</span> КПП <span>771501001</span></li>
                <li>БИК <span>044525593</span></li>
                <li>Кор. счёт <span>30101810200000000593</span></li>
              </ul>
            </LightSpeed>  
            </article>
        </section>
        )}  
        </Media>
      </DocumentTitle>
    );
    
}