import Media from 'react-media'
import  DocumentTitle from 'react-document-title'
import { Component } from 'react'
import axios from 'axios'

import InputMask from 'react-input-mask'
import LightSpeed from 'react-reveal/LightSpeed'
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

export default class Contacts extends Component{
  constructor(props) {
    super(props);
    this.state = {
      uname: '',
      umail: '',
      uphone: '',
      utext: '',
      mailSent: false,
      error: null,
      class: 'submit',
      value: 'Отправить',
      disabled: false,
      sendText: ''
    }
  }

  handleFormSubmit = e => {
    e.preventDefault();
    axios({
      method: 'post',
      url: 'http://spectehtrans.ru/send.php',
      headers: { 'content-type': 'application/json' },
      data: this.state
    })
      .then(result => {
        if(result.data.sent){
          this.setState({
            mailSent: result.data.sent,
            disabled: true,
            class: 'submited',
            value: 'Сообщение отправлено',
            sendText: 'Ваше сообщение отправлено! Спасибо за обращение, мы ответим в ближайшее время.'
          })
        }else{
          this.setState({
            sendText: "Ошибка отправки, заполните форму"
          })
        } 
      })
      .catch(error => this.setState({ error: error.message }));
  }

  render(){
    return(
      <DocumentTitle title={'Обратная связь - Компания СпецТехТранс [г.Москва]'}> 
        <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (
          <section id="unit">
            <Fade top>
              <h1>Обратная связь</h1>
            </Fade>
            <article>
              <Fade top>
              <form method="post">
                {this.state.sendText && <aside className="send-success"><p>{this.state.sendText}</p></aside>}
                <input type="text" name="uname" placeholder="Ваше имя" value={this.state.uname} onChange={e => this.setState({ uname: e.target.value })} required /><br />
                <input type="email" name="umail" placeholder="E-mail" value={this.state.umail} onChange={e => this.setState({ umail: e.target.value })} required /><br />
                <InputMask {...this.props} mask="+7 [ 999 ] 999 - 99 - 99" maskChar=" " name="uphone" placeholder="Телефон" value={this.state.uphone} onChange={e => this.setState({ uphone: e.target.value })} required /><br />
                <textarea name="utext" placeholder="Сообщение" onChange={e => this.setState({ utext: e.target.value })} value={this.state.utext}></textarea><br />
                <input type="submit" className={this.state.class} onClick={e => this.handleFormSubmit(e)} value={this.state.value} disabled={this.state.disabled} />
              </form>
              </Fade>
            </article>
        </section>
          ) : (
            <section id="unit">
            <Rotate top left>
              <h1>Обратная связь</h1>
            </Rotate>
            <article>
              <LightSpeed right cascade>
              <form method="post">
                {this.state.sendText && <aside className="send-success"><p>{this.state.sendText}</p></aside>}
                <input type="text" name="uname" placeholder="Ваше имя" value={this.state.uname} onChange={e => this.setState({ uname: e.target.value })} required /><br />
                <input type="email" name="umail" placeholder="E-mail" value={this.state.umail} onChange={e => this.setState({ umail: e.target.value })} required /><br />
                <InputMask {...this.props} mask="+7 [ 999 ] 999 - 99 - 99" maskChar=" " name="uphone" placeholder="Телефон" value={this.state.uphone} onChange={e => this.setState({ uphone: e.target.value })} required /><br />
                <textarea name="utext" placeholder="Сообщение" onChange={e => this.setState({ utext: e.target.value })} value={this.state.utext}></textarea><br />
                <input type="submit" className={this.state.class} onClick={e => this.handleFormSubmit(e)} value={this.state.value} disabled={this.state.disabled} />
              </form>
              </LightSpeed>
            </article>
        </section>
        )}  
        </Media>
      </DocumentTitle>
    );
  }     
}