import Media from 'react-media'
import  DocumentTitle from 'react-document-title'
import volvoIMG from './img/volvo.jpg'
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

export default function Volvo(props){
    return(
      <DocumentTitle title={'Мобильная дробилка с манипулятором [Volvo Brooks]'}> 
        <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (
          <section id="unit">
          <Fade top>
            <h1>Мобильная дробилка с манипулятором</h1>
          </Fade>
          <Fade top>
            <article>
              <figure><figcaption>Щепоруб [Volvo Brooks]</figcaption><img src={volvoIMG} alt="Щепоруб [Volvo Brooks]" /></figure>
              <p>Измельчитель древесины VOLVO предназначен для дробления порубочных остатков, стволов и крон деревьев, отходов производства лесопиления, горбыля. Отлично подходит для измельчения веток и деревьев вдоль дорог и дробления веток в парках. Универсальная мобильная щепорубильная машина имеет в основе конструкции один измельчающий вал с двумя ножами. Идеально подходит для решения самых сложных задач измельчения древесины.</p>
              <h3>Технические характеристики щепоруба:</h3>
              <ul>
                <li>Полная масса: <span>19000 кг</span></li>
                <li>Мощность: <span>500 л.с.</span></li>
                <li>Производительность: <span>до 100м3 щепы в час</span></li>
              </ul>
               <h3>Сфера применения щепоруба:</h3>
               <p>Мобильный измельчитель предназначен для первичного или вторичного измельчения/дробления древесины в целях уменьшения размера фракции и/или подготовки материла для переработки или утилизации. Область применения: горбыль, целые стволы, порубочные остатки, поддоны и другие древесные отходы и т. д.</p>
               <p>Дробилка древесины с манипулятором - это специальное оборудование, которое используется для измельчения деревянных материалов, таких как стволы, ветки, пни и др., с помощью вращающегося режущего механизма. Для удобства работы дробилка оборудована манипулятором, который помогает загружать и перемещать материалы.</p>
               <p>Манипулятор позволяет оператору управлять процессом работы дробилки, перемещая материал в нужное положение для дальнейшей обработки. Он может быть оснащен гидравлическим приводом, что делает работу более эффективной и удобной.</p>
               <p>Дробилки древесины с манипулятором широко применяются в лесной и деревообрабатывающей промышленности. Они позволяют значительно сократить время и усилия, затрачиваемые на разрушение и переработку деревянных материалов. Также это оборудование способно производить дробленый материал нужного размера, что делает его полезным для использования в строительном и топливном секторах.</p>
               <p>Дробилка древесины с манипулятором - это мощное и эффективное оборудование, которое поможет увеличить производительность и снизить затраты в процессе обработки древесного материала.</p>
               <p>* Щепоруб - это инструмент, предназначенный для измельчения древесных материалов, таких как ветки, дрова или щепа. Он состоит из режущих ножей или молотков, которые раздробляют древесину на мелкие частицы. Щепорубы могут использоваться как в домашних хозяйствах, так и на производственных предприятиях для получения щепы, которая может быть использована для топлива, удобрений или других целей. Щепорубы могут быть ручными или механизированными и имеют различные размеры и мощности в зависимости от назначения.</p>
            </article>
            </Fade>
            </section>
          ) : (
            <section id="unit">
            <Rotate top left>
            <h1>Мобильная дробилка с манипулятором</h1>
            </Rotate>
            <Rotate bottom left>
            <article>
              <figure><figcaption>Щепоруб [Volvo Brooks]</figcaption><img src={volvoIMG} alt="Щепоруб [Volvo Brooks]" /></figure>
              <p>Измельчитель древесины VOLVO предназначен для дробления порубочных остатков, стволов и крон деревьев, отходов производства лесопиления, горбыля. Отлично подходит для измельчения веток и деревьев вдоль дорог и дробления веток в парках. Универсальная мобильная щепорубильная машина имеет в основе конструкции один измельчающий вал с двумя ножами. Идеально подходит для решения самых сложных задач измельчения древесины.</p>
              <h3>Технические характеристики щепоруба:</h3>
              <ul>
                <li>Полная масса: <span>19000 кг</span></li>
                <li>Мощность: <span>500 л.с.</span></li>
                <li>Производительность: <span>до 100м3 щепы в час</span></li>
              </ul>
               <h3>Сфера применения щепоруба:</h3>
               <p>Мобильный измельчитель предназначен для первичного или вторичного измельчения/дробления древесины в целях уменьшения размера фракции и/или подготовки материла для переработки или утилизации. Область применения: горбыль, целые стволы, порубочные остатки, поддоны и другие древесные отходы и т. д.</p>
               <p>Дробилка древесины с манипулятором - это специальное оборудование, которое используется для измельчения деревянных материалов, таких как стволы, ветки, пни и др., с помощью вращающегося режущего механизма. Для удобства работы дробилка оборудована манипулятором, который помогает загружать и перемещать материалы.</p>
               <p>Манипулятор позволяет оператору управлять процессом работы дробилки, перемещая материал в нужное положение для дальнейшей обработки. Он может быть оснащен гидравлическим приводом, что делает работу более эффективной и удобной.</p>
               <p>Дробилки древесины с манипулятором широко применяются в лесной и деревообрабатывающей промышленности. Они позволяют значительно сократить время и усилия, затрачиваемые на разрушение и переработку деревянных материалов. Также это оборудование способно производить дробленый материал нужного размера, что делает его полезным для использования в строительном и топливном секторах.</p>
               <p>Дробилка древесины с манипулятором - это мощное и эффективное оборудование, которое поможет увеличить производительность и снизить затраты в процессе обработки древесного материала.</p>
               <p>* Щепоруб - это инструмент, предназначенный для измельчения древесных материалов, таких как ветки, дрова или щепа. Он состоит из режущих ножей или молотков, которые раздробляют древесину на мелкие частицы. Щепорубы могут использоваться как в домашних хозяйствах, так и на производственных предприятиях для получения щепы, которая может быть использована для топлива, удобрений или других целей. Щепорубы могут быть ручными или механизированными и имеют различные размеры и мощности в зависимости от назначения.</p>
            </article>
            </Rotate>
            </section>
        )}  
        </Media>
      </DocumentTitle>
    );
    
}
