import Media from 'react-media'
import  DocumentTitle from 'react-document-title'
import hammelIMG from './img/hammel.jpg'
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

export default function Hammel(props){
    return(
      <DocumentTitle title={'Шредер для измельчения древесины и ТБО [Hammel 750D]'}> 
        <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (
        <section id="unit">
          <Fade top>
            <h1>Шредер Hammel 750D</h1>
          </Fade>
          <Fade top>
            <article>
            <figure><figcaption>Шредер [Hammel 750D]</figcaption><img src={hammelIMG} alt="Шредер Hammel 750D" /></figure>
              <p>Измельчитель VB 750 D предназначен для дробления крупногабаритных материалов, включая все виды старой, смешанной и сырой древесины, корней и крон деревьев.</p>
              <h3>Технические характеристики Hammel 750D</h3>
              <ul>
                <li>Масса: <span>19000 кг.</span></li>
                <li>Мощность двигателя: <span>350 л.с.</span></li>
                <li>Производительность: <span>до 60 т/час, до 100м3/час</span></li>
                <li>Габаритные размеры: <span>мм 8027/2500/2870</span></li>
                <li>Тип хода: <span>гусеничный</span></li>
                <li>Входящий материал: <span>1500-6000 мм</span></li>
                <li>Выходящий материал: <span>100-400 мм</span></li>
                <li>Высота выброса материала: <span>3700 мм</span></li>
                <li>Аренда <span>- руб/час</span></li>
              </ul>
               <p>Также мобильный шредер успешно перерабатывает шпалы без предварительного отделения металлических предметов, мебели, бытовой техники, кабельных катушек, телефонных столбов, бумажных рулонов, изделий из пластика, использованной автомобильных шин, любых твердых бытовых отходов. Высокая производительность манишы позволяет быстро выполнять свои функции. Организуем доставку на объект.</p>
            </article>
          </Fade>  
        </section>
          ) : (
       <section id="unit">
          <Rotate top left>
            <h1>Шредер Hammel 750D</h1>
          </Rotate>
          <Rotate bottom left>
            <article>
            <figure><figcaption>Шредер [Hammel 750D]</figcaption><img src={hammelIMG} alt="Шредер Hammel 750D" /></figure>
              <p>Измельчитель VB 750 D предназначен для дробления крупногабаритных материалов, включая все виды старой, смешанной и сырой древесины, корней и крон деревьев.</p>
              <h3>Технические характеристики Hammel 750D</h3>
              <ul>
                <li>Масса: <span>19000 кг.</span></li>
                <li>Мощность двигателя: <span>350 л.с.</span></li>
                <li>Производительность: <span>до 60 т/час, до 100м3/час</span></li>
                <li>Габаритные размеры: <span>мм 8027/2500/2870</span></li>
                <li>Тип хода: <span>гусеничный</span></li>
                <li>Входящий материал: <span>1500-6000 мм</span></li>
                <li>Выходящий материал: <span>100-400 мм</span></li>
                <li>Высота выброса материала: <span>3700 мм</span></li>
                <li>Аренда <span>- руб/час</span></li>
              </ul>
               <p>Также мобильный шредер успешно перерабатывает шпалы без предварительного отделения металлических предметов, мебели, бытовой техники, кабельных катушек, телефонных столбов, бумажных рулонов, изделий из пластика, использованной автомобильных шин, любых твердых бытовых отходов. Высокая производительность манишы позволяет быстро выполнять свои функции. Организуем доставку на объект.</p>
            </article>
          </Rotate>  
        </section>
        )}  
        </Media>
      </DocumentTitle>
    );
    
}