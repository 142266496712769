import Media from 'react-media'
import  DocumentTitle from 'react-document-title'
import hitachiIMG from './img/hitachi.jpg'
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

export default function Hitachi(props){
    return(
      <DocumentTitle title={'Корчеватель с гидроножницами Зкскаватор [Hitachi 240-3]'}> 
        <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (
          <section id="unit">
         <Fade top>
            <h1>Корчеватель с гидроножницами</h1>
          </Fade>
          <Fade top>
            <article>
              <figure><figcaption>Зкскаватор [Hitachi 240-3]</figcaption><img src={hitachiIMG} alt="Корчеватель с гидроножницами" /></figure>
              <p>Предлагаем услуги экскаватора Hitachi 240, массой 24 тонны, оснащенного специальными ножницами для извлечения корневой системы деревьев из земли с последующим расколом корня на несколько частей. Корчевание данным методом отлично подготавливает корни деревьев под мульчирование, т.к. во-первых корень достается без земли на нем, а во-вторых раскалывается на части. Масса ножниц для корчевания составляет 1 600 кг.</p>
              <h3>Технические характеристики корчевателя</h3>
              <ul>
                <li>Масса <span>24 тонны</span></li>
                <li>Ширина <span>3.000 мм</span></li>
                <li>Аренда от <span>80.000 руб/га</span></li>
              </ul>
            </article>
          </Fade>
        </section>
          ) : (
            <section id="unit">
         <Rotate top left>
            <h1>Корчеватель с гидроножницами</h1>
          </Rotate>
          <Rotate bottom left>
            <article>
              <figure><figcaption>Зкскаватор [Hitachi 240-3]</figcaption><img src={hitachiIMG} alt="Корчеватель с гидроножницами" /></figure>
              <p>Предлагаем услуги экскаватора Hitachi 240, массой 24 тонны, оснащенного специальными ножницами для извлечения корневой системы деревьев из земли с последующим расколом корня на несколько частей. Корчевание данным методом отлично подготавливает корни деревьев под мульчирование, т.к. во-первых корень достается без земли на нем, а во-вторых раскалывается на части. Масса ножниц для корчевания составляет 1 600 кг.</p>
              <h3>Технические характеристики корчевателя</h3>
              <ul>
                <li>Масса <span>24 тонны</span></li>
                <li>Ширина <span>3.000 мм</span></li>
                <li>Аренда от <span>80.000 руб/га</span></li>
              </ul>
            </article>
          </Rotate>
        </section>
        )}  
        </Media>
      </DocumentTitle>
    );
    
}