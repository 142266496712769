import Media from 'react-media'
import  DocumentTitle from 'react-document-title'
import turboIMG from './img/turbo.jpg'
import Rotate from 'react-reveal/Rotate'
import Fade from 'react-reveal/Fade'

export default function Turbo(props){
    return(
      <DocumentTitle title={'Сжигание порубочных остатков с турбиной'}> 
        <Media queries = {{
          small: '(max-width:960px)',
        }}>
        {(size) => size.small ? (
        <section id="unit">
          <Fade top>
            <h1>Сжигание порубочных остатков</h1>
          </Fade>
          <Fade top>
            <article>
              <figure><figcaption>Турбина для сжигания</figcaption><img src={turboIMG} alt="Турбина для сжигания" /></figure>
              <p>Мы производим утилизацию порубочных остатков и отходов древесины методом сжигания в котловане с воздухонагнетателем.</p>
              <p>Благодаря подаче воздуха под давлением, древесина сгорает с малым выделением дыма. Производительность 30-50м3 в час.</p>
              <p>В работе задействован экскаватор с ножницами для разлома бревен и корней и воздухонагнетатель мощностью 40кВТ</p>
            </article>
          </Fade>
        </section>
          ) : (
        <section id="unit">
          <Rotate top left>
            <h1>Сжигание порубочных остатков</h1>
          </Rotate>
          <Rotate bottom left>
            <article>
              <figure><figcaption>Турбина для сжигания</figcaption><img src={turboIMG} alt="Турбина для сжигания" /></figure>
              <p>Мы производим утилизацию порубочных остатков и отходов древесины методом сжигания в котловане с воздухонагнетателем.</p>
              <p>Благодаря подаче воздуха под давлением, древесина сгорает с малым выделением дыма. Производительность 30-50м3 в час.</p>
              <p>В работе задействован экскаватор с ножницами для разлома бревен и корней и воздухонагнетатель мощностью 40кВТ</p>
            </article>
          </Rotate>
        </section>
        )}  
        </Media>
      </DocumentTitle>
    );
    
}